import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom'
import {databaseTradeIn, databaseExchange} from '../store/store';
// import databaseExchange from 'https://api.privatbank.ua/p24api/pubinfo?json&exchange&coursid=5';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import emailjs from 'emailjs-com'
import * as Yup from 'yup';
import "yup-phone";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel, IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography
} from "@mui/material";

import avatarSmile from '../static/avatar-smile-80-1.png';
import avatarSmile2 from '../static/avatar-smile-80-4.png';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import {SpinnerLoading} from "../components/SpinnerLoading";
import {getMemoryListByModel} from "../components/helpers/getMemoryListByModel";

import MuiAlert from '@mui/material/Alert';

import {TRADEINHELP_ROUTE} from "../routes/const";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const TradeIn = () => {
  const data = databaseTradeIn;
  const dataExchangeDefault = databaseExchange;
  const navigate = useNavigate();

  const form = useRef();
  const phoneRegExp = "/^\+380\d{3}\d{2}\d{2}\d{2}$/";

  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const [exchange, setExchange] = useState(dataExchangeDefault.uahUsdBuy);

  const [models, setModels] = useState([]);
  const [model, setModel] = useState('');
  const [memories, setMemories] = useState([]);
  const [memory, setMemory] = useState('');
  const [price, setPrice] = useState(0);
  const [phone, setPhone] = useState('');

  const [batteryOriginal, setBatteryOriginal] = useState(0);
  const [displayOriginal, setDisplayOriginal] = useState(0);
  const [body, setBody] = useState(0);
  const [fineRepair, setFineRepair] = useState(0);
  const [fineScratch, setFineScratch] = useState(0);

  const [batteryOriginalState, setBatteryOriginalState] = useState(false);
  const [displayOriginalState, setDisplayOriginalState] = useState(false);
  const [bodyState, setBodyState] = useState(false);
  const [fineRepairState, setFineRepairState] = useState(false);
  const [fineScratchState, setFineScratchState] = useState(false);


  const getPriceByModelAndMemory = (model, memory) => {
    return data.filter((item) => item.model === model)[0].memoryPrice[memory];
  }

  // const getFineListByModel = (selectedModel) => {
  //   return Object.fromEntries(Object.entries(data.filter(({model}) => model === selectedModel)[0].fine));
  // }

  const getPriceFineByModel = (selectedModel, fineItem) => {
    return data.filter(({model}) => model === selectedModel)[0].fine[fineItem];
  }

  const getPriceServiceByModel = (selectedModel, serviceItem) => {
    return data.filter(({model}) => model === selectedModel)[0].service[serviceItem];
  }

  const getBatteryOriginal = (selectedModel) => {
    return getPriceServiceByModel(selectedModel, 'batteryOriginal');
  }

  const getDisplayOriginal = (selectedModel) => {
    return getPriceServiceByModel(selectedModel, 'displayOriginal');
  }

  const getBody = (selectedModel) => {
    return getPriceServiceByModel(selectedModel, 'body');
  }

  const getFineRepair = (selectedModel) => {
    return getPriceFineByModel(selectedModel, 'fineRepair');
  }

  const getFineScratch = (selectedModel) => {
    return getPriceFineByModel(selectedModel, 'fineScratch');
  }

  const getPriceTradeIn = () => {
    const calculatePrice = Math.trunc((price * exchange) - ((price * exchange) * fineRepair) - ((price * exchange) * fineScratch) - batteryOriginal - displayOriginal - body);
    return calculatePrice;
  }

  const setExchangeApp = () => {
    fetch('https://api.privatbank.ua/p24api/pubinfo?json&exchange&coursid=5')
      .then((response) => {
        if (response) {
          return response.json();
        }
        return false;
      })
      .then((data) => {
        if (data) {
          const exchangePrivatbank = data.find((item) => 'ccy' in item && item.ccy === 'USD');
          if (exchangePrivatbank && 'sale' in exchangePrivatbank) {
            return setExchange(exchangePrivatbank.sale);
          }
        }
        const exchangeDefault = dataExchangeDefault[0];
        if ('usdSale' in exchangeDefault) {
          return setExchange(exchangeDefault.usdSale)
        }
      }).finally(() => setLoading(false)
    );
  }

  useEffect(() => {
    const initialModel = data[0].model;
    const initialMemoryList = getMemoryListByModel(data, model, initialModel);
    const initialMemory = initialMemoryList[0];
    // const initialFineList = getFineListByModel(initialModel);
    const initialPrice = getPriceByModelAndMemory(initialModel, initialMemory);

    setModels(data.map(item => item.model));
    setModel(initialModel);
    setMemories(initialMemoryList);
    setMemory(initialMemory);
    setPrice(initialPrice);

    setExchangeApp();
  }, []);

  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .required('Телефон обов\'язкове поле')
      .phone('Має бути дійсним номером телефону')
    // .matches(phoneRegExp, 'Має бути дійсним номером телефону'),

  });
  const {register, handleSubmit, formState: {errors}} = useForm({
    resolver: yupResolver(validationSchema)
  });

  const sendEmail = (data) => {
    //e.preventDefault();

    emailjs.sendForm('service_c6tfgr5', 'template_fy1j68p', form.current, 'user_5NDdf5SPDvIsMfhUIMCF8')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });

    handleClick();
  };


  // const onSubmit = async (data) => {
  const onSubmit = async (data) => {

    // try {
    // console.table(data);
    const {
      phone,
      model,
      memory,
      fineBatteryState,
      fineRepairState,
      fineScratchState,
      fineCrackState
    } = data;

    const formData = new FormData();
    formData.append('phone', phone);
    formData.append('model', model);
    formData.append('memory', `${memory}`);
    let addText = '';
    if (fineBatteryState) {
      let sep = addText === '' ? '' : ', ';
      addText = addText + sep + 'ємність батареї < 87%'
    }
    if (fineRepairState) {
      let sep = addText === '' ? '' : ', ';
      addText = addText + sep + 'проводився ремонт телефону'
    }
    if (fineScratchState) {
      let sep = addText === '' ? '' : ', ';
      addText = addText + sep + 'на екрані чи корпусі є подряпини'
    }
    if (fineCrackState) {
      let sep = addText === '' ? '' : ', ';
      addText = addText + sep + 'екран чи задня частина має тріщину або тріщини'
    }
    // console.log('addText', addText);
    formData.append('addInfo', addText);
    await sendEmail(formData);

    // } catch (e) {
    //   console.log('onSubmit error', e.message);
    // }

  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const setPriceByModelAndMemory = (model, memory) => {
    const priceByModelAndMemory = getPriceByModelAndMemory(model, memory);
    setPrice(priceByModelAndMemory);
  }

  const getMemoryBySelectedModel = (selectedModel) => {
    const memoryList = getMemoryListByModel(data, model, selectedModel);
    setMemories(memoryList);
    setMemory(memoryList[0]);
    setPriceByModelAndMemory(selectedModel, memoryList[0]);
  }

  const setDisplayOriginalByModel = (selectedModel) => {
    const value = getDisplayOriginal(selectedModel);
    setDisplayOriginal(value);
  }

  const setBatteryOriginalByModel = (selectedModel) => {
    const value = getBatteryOriginal(selectedModel);
    setBatteryOriginal(value);
  }

  const setBodyByModel = (selectedModel) => {
    const value = getBody(selectedModel);
    setBody(value);
  }

  const setFineRepairByModel = (selectedModel) => {
    const value = getFineRepair(selectedModel);
    setFineRepair(value);
  }

  const setFineScratchByModel = (selectedModel) => {
    const value = getFineScratch(selectedModel);
    setFineScratch(value);
  }

  const setFineZero = () => {
    setBatteryOriginalState(false);
    setDisplayOriginalState(false);
    setBodyState(false)
    setFineRepairState(false);
    setFineScratchState(false);

    setBatteryOriginal(0);
    setDisplayOriginal(0);
    setBody(0)
    setFineRepair(0);
    setFineScratch(0);
  }

  const handleChangeModel = (e) => {
    const {value} = e.target;
    setModel(value);
    getMemoryBySelectedModel(value);
    setFineZero();
  }

  const handleChangeMemory = (e) => {
    const {value} = e.target;
    setMemory(value);
    setPriceByModelAndMemory(model, value);
    setFineZero();
  }

  const handleChangeBatteryOriginalState = () => {
    setBatteryOriginalState(!batteryOriginalState);
    !batteryOriginalState
      ? setBatteryOriginalByModel(model)
      : setBatteryOriginal(0);
  }

  const handleChangeDisplayOriginalState = () => {
    setDisplayOriginalState(!displayOriginalState);
    !displayOriginalState
      ? setDisplayOriginalByModel(model)
      : setDisplayOriginal(0);
  }

  const handleChangeBodyState = () => {
    setBodyState(!bodyState);
    !bodyState
      ? setBodyByModel(model)
      : setBody(0);
  }

  const handleChangeFineRepairState = () => {
    setFineRepairState(!fineRepairState);
    !fineRepairState
      ? setFineRepairByModel(model)
      : setFineRepair(0);
  }

  const handleChangeFineScratchState = () => {
    setFineScratchState(!fineScratchState);
    !fineScratchState
      ? setFineScratchByModel(model)
      : setFineScratch(0);
  }

  const handleChangePhone = (e) => {
    const {value} = e.target;
    setPhone(value);
  }

  const toTradeInHelpPage = () => {
    navigate(TRADEINHELP_ROUTE)
  }

  if (loading) {
    return <SpinnerLoading/>;
  }

  return (
    <Paper
      sx={{
        padding: '1rem'
      }}
    >
      <Box sx={{
        m: 1
      }}>
        {/*<form ref={form} onSubmit={handleSubmit(onSubmit)}>*/}
          {/*<form onSubmit={onSubmit}>*/}
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginBottom: '1.5rem',
          }}>

            <Box sx={{
              margin: '1rem 0 1.5rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'

            }}>
              <Typography variant="h5" component="h1">
                Trade-In Калькулятор
              </Typography>
              <IconButton
                sx={{
                  color: '#71be00'
                }}
                onClick={toTradeInHelpPage}>
                <ContactSupportIcon />
              </IconButton>
            </Box>

            <Box sx={{
              border: '2px solid',
              borderRadius: '6px',
              padding: '1rem 1.5rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: '315px',
              borderColor: getPriceTradeIn() > 0 ? '#71be00' : '#E28130',
            }}>

              <Box sx={{textAlign: 'center'}}>
                {
                  getPriceTradeIn() > 0
                    ? <Typography sx={{fontWeight: 'bold'}} variant="h3" component="h1">{getPriceTradeIn()}</Typography>
                    : <Typography sx={{fontWeight: 'bold', fontSize: '1.15rem'}} variant="h6" component="h2">Безкоштовна<br/> утилізація
                      від<br/> Apple City</Typography>
                }
              </Box>

              <Box sx={{ml: 1}}>
                {
                  getPriceTradeIn() > 0
                    ? <Typography variant="h4" component="h1">₴</Typography>
                    : <Typography variant="h4" component="h1"></Typography>
                }
              </Box>

              <Box sx={{ml: 1,}}>
                {
                  getPriceTradeIn() > 0
                    ? <img src={avatarSmile} alt="avatar"/>
                    : <img src={avatarSmile2} alt="avatar"/>
                }
              </Box>
            </Box>

            <Box
              sx={{
                maxWidth: '320px',
                fontSize: '0.7rem',
                margin: '0.5rem 0',
                textAlign: 'center',
                color: getPriceTradeIn() > 0 ? '#71be00' : '#E28130',
              }}
            >
              {
              getPriceTradeIn() > 0
                ?
                <Typography variant="string" component="p">
                  калькулятор це лише бездушна машина, а наш експерт з Trade-In справжній естет та знавець Apple, запитайте у нього більш точну оцінку
                </Typography>
                :
                <Typography variant="string" component="p">
                  не засмучуйтеся, калькулятор лише бездушна машина, проте наш експерт з Trade-In має велике серце і його оцінка може вас потішити
                </Typography>
              }
            </Box>
          </Box>



          <form ref={form} onSubmit={handleSubmit(onSubmit)}>
          <FormControl variant="standard" fullWidth>
            <InputLabel id="label-model">Модель</InputLabel>
            <Select
              {...register('model')}
              labelId="label-model"
              id="id-model"
              name={'model'}
              value={model}
              label="Телефон"
              onChange={handleChangeModel}
              sx={{
                fontSize: '1.8rem',
                mb: '1.5rem'
              }}
            >
              {models.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
            </Select>
          </FormControl>

          <FormControl variant="standard" fullWidth>
            <InputLabel id="label-memory">Пам'ять</InputLabel>
            <Select
              {...register('memory')}
              labelId="label-memory"
              id="id-memory"
              name={'memory'}
              value={memory}
              label="Об\'єм"
              onChange={handleChangeMemory}
              sx={{
                fontSize: '1.8rem',
                mb: '1.5rem',
              }}
            >
              {memories.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
            </Select>
          </FormControl>

          <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">В процесі експлуатації:</FormLabel>
            <FormGroup
              sx={{
                mb: '1.5rem'
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    {...register('batteryOriginalState')}
                    name={'batteryOriginalState'}
                    checked={batteryOriginalState}
                    onChange={handleChangeBatteryOriginalState}
                  />

                }
                label="ємність батареї < 87%"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    {...register('displayOriginalState')}
                    name={'displayOriginalState'}
                    checked={displayOriginalState}
                    onChange={handleChangeDisplayOriginalState}
                  />
                }
                label="тріснутий дисплей"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    {...register('bodyState')}
                    name={'bodyState'}
                    checked={bodyState}
                    onChange={handleChangeBodyState}
                  />
                }
                label="пошкоджений корпус"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    {...register('fineRepairState')}
                    name={'fineRepairState'}
                    checked={fineRepairState}
                    onChange={handleChangeFineRepairState}
                  />
                }
                label="вже проводився ремонт"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    {...register('fineScratchState')}
                    name={'fineScratchState'}
                    checked={fineScratchState}
                    onChange={handleChangeFineScratchState}
                  />
                }
                label="подряпини на корпусі/екрані"
              />
            </FormGroup>

            <FormControl variant="standard" fullWidth>
              <TextField
                {...register('phone')}
                id="user-phone"
                name={'phone'}
                label="Залиште номер вашого телефону"
                placeholder="+380679995511"
                value={phone}
                onChange={handleChangePhone}
                sx={{
                  fontSize: '1rem',
                  mb: '1rem'
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIphoneIcon/>
                    </InputAdornment>
                  ),
                  inputMode: 'numeric',
                  pattern: '[0-9]'
                }}
                error={!!errors.phone}
                helperText={errors.phone ? errors.phone.message : null}
                variant="standard"
              />
            </FormControl>

            <Button sx={{mb: '1rem'}} type='submit'>Відправити</Button>

            <Box sx={{mb: '0.5rem', display: 'flex', justifyContent: 'center'}}>
              <Button variant='outlined' color='success'>
                <a href={'tel:+380670095577'} style={{color: '#71be00', textDecoration: 'none'}}> Зв'язок з Экспертом з
                  Trade-In</a>
              </Button>
            </Box>

            <FormHelperText sx={{textAlign: 'justify'}}>
              Увага! Слід розуміти, що реальна оцінка вашого телефону відбувається тільки при огляді експертом Trade-In
              мережі Apple City.
              <br/><br/>
              Так само врахуйте, що Trade-In - не програма за якою викуповуються телефони, це програма обміну вашого
              телефону на нову техніку Apple, з різницею, приблизний еквівалент якої, ви бачите на екрані.
            </FormHelperText>
          </FormControl>

        </form>
      </Box>

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
          Запит відправлено експерту з Trade-In!
        </Alert>
      </Snackbar>
    </Paper>
  );
};

