import React from 'react';
import {Route, Routes} from "react-router-dom";
import {authRoutes, publicRoutes} from "../routes/routes";
import {TRADEIN_ROUTE} from "../routes/const";
import {TradeIn} from "../page/TradeIn";

export const AppRoutes = ({isAuth}) => {
  // console.log('isAuth', isAuth);
  return (
    <Routes>
      {isAuth && authRoutes.map(({path, Component}) =>
        <Route key={path} path={path} element={Component} exact/>
      )}
      {publicRoutes.map(({path, Component}) =>
        <Route key={path} path={path} element={Component} exact/>
      )}
      <Route path={TRADEIN_ROUTE} element={TradeIn} exact/>
    </Routes>
  );
};
