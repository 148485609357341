import {HOME_ROUTE, TRADEIN_ROUTE, ADMIN_ROUTE, TRADEINHELP_ROUTE} from './const';
import {Home} from '../page/Home'
import {TradeIn} from '../page/TradeIn'
import {Admin} from '../page/Admin'
import {TradeInHelp} from "../page/TradeInHelp";

export const publicRoutes = [
  {
    path: TRADEIN_ROUTE,
    Component: <TradeIn/>
  },
  {
    path: TRADEINHELP_ROUTE,
    Component: <TradeInHelp/>
  },
  // {
  //   path: HOME_ROUTE,
  //   Component: <Home/>
  // }
];

export const authRoutes = [
  {
    path: ADMIN_ROUTE,
    Component: <Admin/>
  }
]