import React from 'react';
import {Box, CircularProgress} from "@mui/material";

export const SpinnerLoading = () => {
  return (
    <Box style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <CircularProgress color="secondary" size={40}/>
    </Box>
  );
};
