export const databaseExchange = [
  {
    usdSale: 26.5,
  },
]

export const databaseTradeIn = [
  {
    model: 'iPhone 7',
    memoryPrice: {
      32: 140,
      128: 170,
      256: 180
    },
    fine:   {
      fineRepair: 0.1,
      fineScratch: 0.1,
    },
    service: {
      displayOriginal: 4950,
      displayAnalog: 1600,
      glassReplaceAnalog: 0,
      batteryOriginal: 1500,
      batteryAnalog: 700,
      cameraMainOriginal: 1900,
      cameraFrontOriginal: 1900,
      motherboard: 0,
      cleaningAudioChannelsMesh: 350,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 5000,
      buttonHomeAnalog: 850,
      restoringTightness: 200,
      buttonPower: 1000,
      speaker: 1000,
      jackHeadphones: 0,
      body: 1500,
      chargingCable: 1800,
      microphone: 0,
      glassCameraAnalog: 1300,
    }
  },
  {
    model: 'iPhone 7 Plus',
    memoryPrice: {
      32: 170,
      128: 220,
      256: 245
    },
    fine:   {
      fineRepair: 0.1,
      fineScratch: 0.1,
    },
    service: {
      displayOriginal: 5600,
      displayAnalog: 2200,
      glassReplaceAnalog: 0,
      batteryOriginal: 1500,
      batteryAnalog: 700,
      cameraMainOriginal: 2800,
      cameraFrontOriginal: 1900,
      motherboard: 0,
      cleaningAudioChannelsMesh: 350,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 6000,
      buttonHomeAnalog: 850,
      restoringTightness: 200,
      buttonPower: 1000,
      speaker: 1000,
      jackHeadphones: 0,
      body: 1500,
      chargingCable: 1800,
      microphone: 0,
      glassCameraAnalog: 1300,
    }
  },
  {
    model: 'iPhone 8',
    memoryPrice: {
      64: 170,
      128: 200,
      256: 210
    },
    fine:   {
      fineRepair: 0.1,
      fineScratch: 0.1,
    },
    service: {
      displayOriginal: 5300,
      displayAnalog: 1900,
      glassReplaceAnalog: 1500,
      batteryOriginal: 1500,
      batteryAnalog: 700,
      cameraMainOriginal: 2900,
      cameraFrontOriginal: 1900,
      motherboard: 0,
      cleaningAudioChannelsMesh: 350,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 5600,
      buttonHomeAnalog: 0,
      restoringTightness: 200,
      buttonPower: 0,
      speaker: 1300,
      jackHeadphones: 0,
      body: 2000,
      chargingCable: 1800,
      microphone: 1300,
      glassCameraAnalog: 1300,
    }
  },
  {
    model: 'iPhone 8 Plus',
    memoryPrice: {
      64: 260,
      128: 290,
      256: 300
    },
    fine:   {
      fineRepair: 0.1,
      fineScratch: 0.1,
    },
    service: {
      displayOriginal: 6100,
      displayAnalog: 2500,
      glassReplaceAnalog: 1500,
      batteryOriginal: 1500,
      batteryAnalog: 700,
      cameraMainOriginal: 2600,
      cameraFrontOriginal: 1900,
      motherboard: 0,
      cleaningAudioChannelsMesh: 350,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 5900,
      buttonHomeAnalog: 0,
      restoringTightness: 200,
      buttonPower: 0,
      speaker: 1300,
      jackHeadphones: 0,
      body: 2000,
      chargingCable: 1800,
      microphone: 1300,
      glassCameraAnalog: 0,
    }
  },
  {
    model: 'iPhone X',
    memoryPrice: {
      64: 330,
      256: 370
    },
    fine:   {
      fineRepair: 0.1,
      fineScratch: 0.1,
    },
    service: {
      displayOriginal: 9100,
      displayAnalog: 0,
      glassReplaceAnalog: 2300,
      batteryOriginal: 2700,
      batteryAnalog: 0,
      cameraMainOriginal: 3000,
      cameraFrontOriginal: 3500,
      motherboard: 0,
      cleaningAudioChannelsMesh: 500,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 0,
      buttonHomeAnalog: 0,
      restoringTightness: 200,
      buttonPower: 0,
      speaker: 0,
      jackHeadphones: 0,
      body: 2000,
      chargingCable: 1900,
      microphone: 1900,
      glassCameraAnalog: 2000,
    }
  },
  {
    model: 'iPhone Xs',
    memoryPrice: {
      64: 370,
      256: 420,
      512: 445
    },
    fine:   {
      fineRepair: 0.1,
      fineScratch: 0.1,
    },
    service: {
      displayOriginal: 9200,
      displayAnalog: 0,
      glassReplaceAnalog: 2400,
      batteryOriginal: 2700,
      batteryAnalog: 2900,
      cameraMainOriginal: 4000,
      cameraFrontOriginal: 0,
      motherboard: 0,
      cleaningAudioChannelsMesh: 500,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 0,
      buttonHomeAnalog: 0,
      restoringTightness: 200,
      buttonPower: 0,
      speaker: 0,
      jackHeadphones: 0,
      body: 2000,
      chargingCable: 2500,
      microphone: 2500,
      glassCameraAnalog: 2000,
    }
  },
  {
    model: 'iPhone Xs Max',
    memoryPrice: {
      64: 450,
      256: 500,
      512: 540
    },
    fine:   {
      fineRepair: 0.1,
      fineScratch: 0.1,
    },
    service: {
      displayOriginal: 10700,
      displayAnalog: 0,
      glassReplaceAnalog: 3300,
      batteryOriginal: 2700,
      batteryAnalog: 2900,
      cameraMainOriginal: 4000,
      cameraFrontOriginal: 0,
      motherboard: 0,
      cleaningAudioChannelsMesh: 500,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 0,
      buttonHomeAnalog: 0,
      restoringTightness: 200,
      buttonPower: 0,
      speaker: 0,
      jackHeadphones: 0,
      body: 2500,
      chargingCable: 2500,
      microphone: 2500,
      glassCameraAnalog: 2000,
    }
  },
  {
    model: 'iPhone Xr',
    memoryPrice: {
      64: 340,
      128: 380,
      256: 400
    },
    fine:   {
      fineRepair: 0.1,
      fineScratch: 0.1,
    },
    service: {
      displayOriginal: 6700,
      displayAnalog: 0,
      glassReplaceAnalog: 2300,
      batteryOriginal: 2700,
      batteryAnalog: 0,
      cameraMainOriginal: 3000,
      cameraFrontOriginal: 0,
      motherboard: 0,
      cleaningAudioChannelsMesh: 500,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 0,
      buttonHomeAnalog: 0,
      restoringTightness: 200,
      buttonPower: 0,
      speaker: 0,
      jackHeadphones: 0,
      body: 2000,
      chargingCable: 2500,
      microphone: 2500,
      glassCameraAnalog: 2000,
    }
  },
  {
    model: 'iPhone 11',
    memoryPrice: {
      64: 480,
      128: 530,
      256: 550
    },
    fine:   {
      fineRepair: 0.1,
      fineScratch: 0.1,
    },
    service: {
      displayOriginal: 6700,
      displayAnalog: 0,
      glassReplaceAnalog: 3300,
      batteryOriginal: 2700,
      batteryAnalog: 0,
      cameraMainOriginal: 3400,
      cameraFrontOriginal: 0,
      motherboard: 0,
      cleaningAudioChannelsMesh: 500,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 0,
      buttonHomeAnalog: 0,
      restoringTightness: 200,
      buttonPower: 0,
      speaker: 0,
      jackHeadphones: 0,
      body: 2500,
      chargingCable: 2500,
      microphone: 0,
      glassCameraAnalog: 2000,
    }
  },
  {
    model: 'iPhone 11 Pro',
    memoryPrice: {
      64: 650,
      256: 730,
      512: 760
    },
    fine:   {
      fineRepair: 0.1,
      fineScratch: 0.1,
    },
    service: {
      displayOriginal: 9200,
      displayAnalog: 0,
      glassReplaceAnalog: 3600,
      batteryOriginal: 2700,
      batteryAnalog: 0,
      cameraMainOriginal: 3700,
      cameraFrontOriginal: 0,
      motherboard: 0,
      cleaningAudioChannelsMesh: 500,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 0,
      buttonHomeAnalog: 0,
      restoringTightness: 200,
      buttonPower: 0,
      speaker: 0,
      jackHeadphones: 0,
      body: 2500,
      chargingCable: 0,
      microphone: 0,
      glassCameraAnalog: 2000,
    }
  },
  {
    model: 'iPhone 11 Pro Max',
    memoryPrice: {
      64: 740,
      256: 840,
      512: 860
    },
    fine:   {
      fineRepair: 0.1,
      fineScratch: 0.1,
    },
    service: {
      displayOriginal: 10700,
      displayAnalog: 0,
      glassReplaceAnalog: 3600,
      batteryOriginal: 2700,
      batteryAnalog: 0,
      cameraMainOriginal: 3700,
      cameraFrontOriginal: 0,
      motherboard: 0,
      cleaningAudioChannelsMesh: 500,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 0,
      buttonHomeAnalog: 0,
      restoringTightness: 200,
      buttonPower: 0,
      speaker: 0,
      jackHeadphones: 0,
      body: 3000,
      chargingCable: 0,
      microphone: 0,
      glassCameraAnalog: 2000,
    }
  },
  {
    model: 'iPhone SE 2',
    memoryPrice: {
      64: 200,
      128: 230,
      256: 250
    },
    fine:   {
      fineRepair: 0.1,
      fineScratch: 0.1,
    },
    service: {
      displayOriginal: 5300,
      displayAnalog: 1400,
      glassReplaceAnalog: 1500,
      batteryOriginal: 1500,
      batteryAnalog: 600,
      cameraMainOriginal: 1900,
      cameraFrontOriginal: 900,
      motherboard: 0,
      cleaningAudioChannelsMesh: 250,
      cleaningAudioChannelsBoozer: 350,
      buttonHomeOriginal: 0,
      buttonHomeAnalog: 850,
      restoringTightness: 200,
      buttonPower: 700,
      speaker: 700,
      jackHeadphones: 700,
      body: 500,
      chargingCable: 900,
      microphone: 700,
      glassCameraAnalog: 0,
    }
  },
  {
    model: 'iPhone 12',
    memoryPrice: {
      64: 800,
      128: 850,
      256: 880
    },
    fine:   {
      fineRepair: 0.1,
      fineScratch: 0.1,
    },
    service: {
      displayOriginal: 9200,
      displayAnalog: 0,
      glassReplaceAnalog: 5500,
      batteryOriginal: 2700,
      batteryAnalog: 0,
      cameraMainOriginal: 3700,
      cameraFrontOriginal: 0,
      motherboard: 14100,
      cleaningAudioChannelsMesh: 700,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 0,
      buttonHomeAnalog: 0,
      restoringTightness: 200,
      buttonPower: 0,
      speaker: 0,
      jackHeadphones: 0,
      body: 5200,
      chargingCable: 0,
      microphone: 0,
      glassCameraAnalog: 0,
    }
  },
  {
    model: 'iPhone 12 mini',
    memoryPrice: {
      64: 500,
      128: 540,
      256: 580
    },
    fine:   {
      fineRepair: 0.1,
      fineScratch: 0.1,
    },
    service: {
      displayOriginal: 7900,
      displayAnalog: 0,
      glassReplaceAnalog: 5500,
      batteryOriginal: 2700,
      batteryAnalog: 0,
      cameraMainOriginal: 3700,
      cameraFrontOriginal: 0,
      motherboard: 0,
      cleaningAudioChannelsMesh: 700,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 0,
      buttonHomeAnalog: 0,
      restoringTightness: 200,
      buttonPower: 0,
      speaker: 0,
      jackHeadphones: 0,
      body: 5200,
      chargingCable: 0,
      microphone: 0,
      glassCameraAnalog: 0,
    }
  },
  {
    model: 'iPhone 12 Pro',
    memoryPrice: {
      128: 900,
      256: 950,
      512: 1000
    },
    fine:   {
      fineRepair: 0.1,
      fineScratch: 0.1,
    },
    service: {
      displayOriginal: 9200,
      displayAnalog: 0,
      glassReplaceAnalog: 5500,
      batteryOriginal: 2700,
      batteryAnalog: 0,
      cameraMainOriginal: 4100,
      cameraFrontOriginal: 0,
      motherboard: 17400,
      cleaningAudioChannelsMesh: 700,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 0,
      buttonHomeAnalog: 0,
      restoringTightness: 200,
      buttonPower: 0,
      speaker: 0,
      jackHeadphones: 0,
      body: 5500,
      chargingCable: 0,
      microphone: 0,
      glassCameraAnalog: 0,
    }
  },
  {
    model: 'iPhone 12 Pro Max',
    memoryPrice: {
      128: 1050,
      256: 1100,
      512: 1150
    },
    fine:   {
      fineRepair: 0.1,
      fineScratch: 0.1,
    },
    service: {
      displayOriginal: 10700,
      displayAnalog: 0,
      glassReplaceAnalog: 0,
      batteryOriginal: 2700,
      batteryAnalog: 0,
      cameraMainOriginal: 4100,
      cameraFrontOriginal: 0,
      motherboard: 20000,
      cleaningAudioChannelsMesh: 700,
      cleaningAudioChannelsBoozer: 500,
      buttonHomeOriginal: 0,
      buttonHomeAnalog: 0,
      restoringTightness: 200,
      buttonPower: 0,
      speaker: 0,
      jackHeadphones: 0,
      body: 5500,
      chargingCable: 0,
      microphone: 0,
      glassCameraAnalog: 0,
    }
  },
]
